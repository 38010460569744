<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Solicitudes Transporte Interno</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tep Voy Por Ti</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">
                    Solicitudes Transporte Interno
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="create()"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="solicitudes"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>
                        Solicitud<input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.id_solicitud"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Usuario<input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.usuario"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Tipo Vehiculo<input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_vehiculo"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Origen<input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.origen"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Destino<input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.destino"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Fecha Inicio
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_ini"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Fecha Fin
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_fin"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Operacion
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_operacion"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="tipo_operacion in listasForms.tipo_operacion"
                            :key="tipo_operacion.numeracion"
                            :value="tipo_operacion.numeracion"
                          >
                            {{ tipo_operacion.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in solicitudes.data" :key="item.id">
                      <td>{{ item.id }}</td>
                      <td>{{ item.user.name }}</td>
                      <td>{{ item.tipo_vehiculo.nombre }}</td>
                      <td>{{ item.direccion_origen }}</td>
                      <td>{{ item.direccion_destino }}</td>
                      <td>{{ item.fecha_hora_inicio_servicio }}</td>
                      <td>{{ item.fecha_hora_fin_servicio }}</td>
                      <td class="text-center">
                        <div v-if="item.estado == 1">
                          <span class="badge badge-warning">
                            {{ item.nEstado }}
                          </span>
                        </div>
                        <div v-else-if="item.estado == 2">
                          <span class="badge badge-success">
                            {{ item.nEstado }}
                          </span>
                        </div>
                        <div v-else-if="item.estado == 3">
                          <span class="badge badge-info">
                            {{ item.nEstado }}
                          </span>
                        </div>
                        <div v-else-if="item.estado == 4">
                          <span class="badge badge-danger">
                            {{ item.nEstado }}
                          </span>
                        </div>
                        <div v-else-if="item.estado == 5">
                          <span class="badge badge-primary">
                            {{ item.nEstado }}
                          </span>
                        </div>
                        <div v-else-if="item.estado == 6">
                          <span class="badge badge-success">
                            {{ item.nEstado }}
                          </span>
                        </div>
                        <div v-else-if="item.estado == 7">
                          <span class="badge badge-danger">
                            {{ item.nEstado }}
                          </span>
                        </div>
                      </td>
                      <td>{{ item.nEstadoOperacion }}</td>
                      <td>
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            @click="edit(item)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm bg-purple"
                            style="cursor: pointer"
                            data-toggle="modal"
                            data-target="#modal-form-detalle-tepsolicitud"
                            @click="llenarModal(item)"
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="solicitudes.total">
                  <p>
                    Mostrando del <b>{{ solicitudes.from }}</b> al
                    <b>{{ solicitudes.to }}</b> de un total:
                    <b>{{ solicitudes.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="solicitudes"
                  @pagination-change-page="getIndex"
                  :limit="10"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <TepSolicitudesDetalle ref="TepSolicitudesDetalle" />
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import pagination from "laravel-vue-pagination";
import TepSolicitudesDetalle from "../../../tep/internos/solicitudes/TepSolicitudDetalle";
export default {
  name: "SolicitudInternaIndex",
  components: {
    Loading,
    pagination,
    TepSolicitudesDetalle,
  },
  data() {
    return {
      cargando: false,
      filtros: {
        id_solicitud: null,
        usuario: null,
        origen: null,
        estado: null,
        tipo_operacion: null,
        estado_inspeccion: null,
        destino: null,
        fecha_fin: null,
        fecha_ini: null,
      },
      solicitudes: {},
      listasForms: {
        estados: [],
        tipo_operacion: [],
        estado_inspeccion: [],
      },
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/funcionariosFrontera/SolicitudesInternas?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.solicitudes = response.data;
          this.cargando = false;
        });
    },

    create() {
      return this.$router.push({
        name: "/TepVoyPorTi/FuncionariosSolicitudesInternasForm",
        params: { accion: "Crear", id: 0 },
      });
    },

    edit(solicitudInterna) {
      return this.$router.push({
        name: "/TepVoyPorTi/FuncionariosSolicitudesInternasForm",
        params: {
          accion: "Editar",
          data_edit: solicitudInterna,
          id: solicitudInterna.id,
        },
      });
    },

    getEstados() {
      axios.get("/api/lista/84").then((response) => {
        this.listasForms.estados = response.data;
      });
    },
    getEstadosInspeccion() {
      axios.get("/api/lista/92").then((response) => {
        this.listasForms.estado_inspeccion = response.data;
      });
    },
    getTipoOperacion() {
      axios.get("/api/lista/80").then((response) => {
        this.listasForms.tipo_operacion = response.data;
      });
    },
    llenarModal(item) {
      this.$refs.TepSolicitudesDetalle.llenar_modal(item);
    },

    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
  },

  mounted() {
    this.getIndex();
    this.getEstados();
    this.getEstadosInspeccion();
    this.getTipoOperacion();
    this.getMsg();
  },
};
</script>
